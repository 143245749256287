<script>
export default {
  name: 'FormFeedMixins',
  data: () => ({
    loading: 0,
    selected: 0,
    showSaveMultiple: false,
    fields: [
      'title',
      'fileformat',
      'fileformat_list',
      'fileformat_fields',
      'fileformat_columns',
      'fileformat_hooks',
      'examples',
      'protocol',
      'protocol_params',
      'need_refresh',
      'auto_refresh',
      'disabled',
      'legacy_html_to_md',
      'max_churn_percent',
    ],
    // database values
    loaded_values: undefined,
    // default values
    default_values: {
      title: undefined,
      fileformat: undefined,
      fileformat_list: undefined,
      fileformat_fields: [],
      fileformat_columns: [],
      fileformat_hooks: [],
      examples: [],
      count: 0,
      protocol: 'http',
      protocol_params: {url:'', method:'get'},
      auto_refresh: undefined,
      disabled: undefined,
      legacy_html_to_md: undefined,
      max_churn_percent: undefined,
    },
    // current values
    values: {
      title: undefined,
      fileformat: undefined,
      fileformat_list: undefined,
      fileformat_fields: undefined,
      fileformat_columns: undefined,
      fileformat_hooks: undefined,
      examples: [],
      count: 0,
      protocol: undefined,
      protocol_params: undefined,
      auto_refresh: undefined,
      disabled: undefined,
      legacy_html_to_md: undefined,
      max_churn_percent: undefined,
    },
    fileError: undefined,
  }),
  props: {
    ids: {
      type: [Array, Boolean],
      default: () => ([])
    },
    props: {
      type: [Object, Boolean],
      default: () => ({})
    }
  },
  watch: {
    ids (n,o) {
      if (n && n.length > 0) {
        if (n[this.selected].id !== this.id) {
          this.selected = 0
          this.loadItem()
        }
      }
    }
  },
  computed: {
    canPrev () {
      return this.selected > 0 && this.ids.length > 0
    },
    canNext () {
      return this.selected < this.ids.length-1 && this.ids.length > 0
    },
    id () {
      return this.loaded_values ? this.loaded_values.id : null
    }
  },
  created () {
    if (this.ids.length > 0) {
      this.loadItem()
        .then(() => this.testFile())
    } else {
      this.resetItem()
    }
  },
  methods: {

    // apply changes from another data (child component)
    handleInput (values) {
      values = this.$clone(values)
      for (const field of this.fields) {
        if (typeof values[field] !== 'undefined') {
          if (values[field] instanceof Object && !(values[field] instanceof Array)) {
            this.values[field] = Object.assign({}, this.values[field], values[field])
          } else {
            this.values[field] = values[field]
          }
        }
      }
      if (values.protocol || values.protocol_params) {
        this.testFile()
      }
    },


    // init and reset values
    resetItem () {
      const values = this.$clone(this.id ? this.loaded_values : this.default_values)
      for (const field of this.fields) {
        if (typeof values[field] !== 'undefined') this.values[field] = values[field]
      }
    },


    // load prev item on the fly
    prevItem () {
      if (this.canPrev) {
        this.selected--
        return this.loadItem()
      }
    },


    // load next item on the fly
    nextItem () {
      if (this.canNext) {
        this.selected++
        return this.loadItem()
      }
    },


    // load and test file
    testFile () {
      this.loading = true
      if (this.values.protocol && this.values.protocol_params) {
        const query = {
          values: {
            protocol: this.values.protocol,
            protocol_params: this.values.protocol_params
          }
        }
        this.$services.api_programmatic.feed.test(query)
          .then(response => {
            this.fileError = null
            this.values.fileformat_columns = response.data.columns
            this.values.fileformat_list = response.data.list[0]
            this.values.fileformat = response.data.format
            this.values.count = response.data.count
            this.values.examples = response.data.dataList
          })
          .catch(e => {
            this.fileError = e.message
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },


    // load item
    loadItem () {
      this.loading = true
      const query = {where: {id: this.ids[this.selected]}}
      return this.$services.api_programmatic.feed.search(query)
        .then(response => {
          if (response.data && response.data[0]) {
            this.loaded_values = this.$clone(response.data[0])
          }
          return response
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.resetItem()
          this.loading = false
        })

    },


    // save item
    saveItem (multiple = false) {
      if (this.ids.length > 1 && (typeof multiple !== 'boolean' || !multiple)) return this.showSaveMultiple = true
      this.showSaveMultiple = false
      this.loading = true
      if (!this.id) {
        const values = {}
        for (const field of this.fields) {
          if (typeof this.values[field] !== 'undefined') values[field] = this.values[field]
        }
        return this.$services.api_programmatic.feed.create({values})
          .then(response => {
            if (response.data) {
              this.$emit('create', response.data[1])
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        const values = this.$compare(this.loaded_values, this.values, this.fields)
        if (Object.keys(values).length > 0) {
          // to change protocol_params, pass protocol too
          if (values.protocol_params && !values.protocol) values.protocol = this.values.protocol
          return this.$services.api_programmatic.feed.update({values, where: {id: this.id}})
            .then(response => {
              if (response.data) {
                this.$emit('update', this.id)
              }
            })
            .catch(e => {
              this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      }
    },


    // remove item
    removeItem () {
      if (this.id) {
        this.loading = true
        return this.$services.api_programmatic.feed.update({values: {status:-1}, where: {id: this.id}})
          .then(response => {
            if (response.data) {
              this.$emit('remove', this.id)
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        console.warning('You cannot remove element without its id.')
      }
    },


    // refresh
    refreshItem () {
      if (this.id) {
        this.loading = true
        return this.$services.api_programmatic.feed.refresh({where: {id: this.id}})
          .then(response => {
            if (response.data) {
              this.$emit('update', this.id)
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    toggleDisableItem () {
      if (this.id) {
        this.loading = true

        const values = {
          disabled: this.loaded_values && this.loaded_values.disabled ? false : true,
        };

        return this.$services.api_programmatic.feed.update({values, where: {id: this.id}})
          .then(response => {
            if (response.data) {
              this.$emit('update', this.id)
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    cancelRefresh () {
      if (this.id) {
        this.loading = true
        return this.$services.api_programmatic.feed.update({where: {id: this.id}, values: {need_refresh: null, status_type:'finished', status_code: 'FEED.REFRESH_STOPPED', status_message: null}})
          .then(response => {
            this.$emit('update', this.id)
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  }
}
</script>
